.App {
  /*text-align: center; */
}

/* Default: Hide mobile and show desktop */
.mobile-div {
  display: none;
}

.desktop-div {
  display: block;
}

/* For screens smaller than 768px (mobile and tablet) */
@media (max-width: 300px) {
  .mobile-div {
    display: block;
  }

  .desktop-div {
    display: none;
  }
}

.black-btn {
  background-color: black;
  color: white;
  border-color: #1DB954;
}

.black-btn:hover {
  background-color: gray;
  color: #1DB954;
}

.green-btn {
  background-color: #1DB954;
  color: black;
}
.green-btn:hover {
  background-color: black;
  color: #1DB954;
  border-color: #1DB954;
}

body::-webkit-scrollbar {
  display: none;
}

.green {
  color: #1DB954;
}