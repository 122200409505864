.modal {
    position: fixed;
    z-index: 1000;
    display:block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4); /* Black with opacity */
}
  
  .modal-content {
    position: fixed;
    background-color: #1DB954;
    padding: 20px;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    max-width: 500px;
    width: 80%;

    color: white;

  }
  .list-item:hover {
    text-decoration: underline;
  }